import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  @Input() public data;
  @Input() public isMarshInsuranceApplicalbe;
  amount = 0;
  @Input() public currency: any = ''
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    // this.currency = this.data.currency.split('$')[1].split(' ')[0];
    if(this.isMarshInsuranceApplicalbe){
      this.amount = this.data.currency.split('$')[1].split(' ')[0]
    }
    else{
    this.amount = this.data.currency.split('$')[1].split(' ')[1]
    }
    // console.log(this.currency)
  }
  closeModal() { this.activeModal.close({ success: false }); }

  optInsaurance() {
    this.activeModal.close({ success: true });
  }
}
