<h3 class="text-center responsive_heading"
    style="font-size: 18px !important; color: #354052; font-family: poppins,sans-serif; font-weight: 500 !important;">
    Add Shipcarte Insurance</h3>
<div class="outer_wrapper" style="padding:20px;margin-top: -30px;">
    <h3 class="text-center desktop_heading"
        style="font-size: 18px !important; color: #354052; font-family: poppins,sans-serif; font-weight: 500 !important;">
        Add Shipcarte Insurance</h3>
    <img class="d-block m-auto insurance_icon_logo" src="assets/img/Currier-Selection/Insurance_icon.svg" width="10%">
    <p
        style="font-size: 16px !important; color: #354052; font-family: poppins,sans-serif; font-weight: 400 !important;margin-bottom: 20px !important;">
        Unless the consignor has declared a value of the goods on the face of the Bill of Lading, the amount
        of any loss or damage for which the carrier is liable, whether or not such loss or damage
        results from negligence, shall be the lesser of:</p>

    <ol *ngIf="isMarshInsuranceApplicalbe" style="padding: 10px;"
        style="font-size: 16px !important; color: #354052; font-family: poppins,sans-serif; font-weight: 400 !important; margin-bottom: 20px !important;">
        <li style="margin-bottom: 13px !important;" *ngFor="let item of data.conditionArray">
            {{item}}
        </li>
        <!-- <li style="margin-bottom: 15px !important;">The value of the goods at the place and time of shipment, including freight and other charges if paid, and</li>
                <li>$4.41 per kilogram computed on the total weight of the shipment</li> -->
    </ol>
    <ol class="insaurance"
        style="font-size: 16px !important; color: #354052; font-family: poppins,sans-serif; font-weight: 400 !important; margin-bottom: 20px !important;"
        *ngIf="!isMarshInsuranceApplicalbe">
        <li style="margin-bottom:10px ;">The value of the goods at the place and time of
            shipment, including freight and other charges if paid, and</li>
        <li>$ 4.41 per kilogram computed on the total weight of the shipment</li>
    </ol>
    <div class="row amount">
        <!-- <input style="pointer-events: fill;" type="checkbox" id="checkbox" />
			<label for="checkbox" style="    margin: 2px 10px 17px 20px;
            position: absolute;">Do not show me this again</label> -->
        <div class="col-9 text-center"
            style="font-size: 16px; font-family: poppins,sans-serif; text-align: left !important;">
            Insurance Cost for shipment
        </div>
        <div class="col-3 text-center">
            <div class="total_count text-right"
                style="font-size: 15px; font-family: poppins,sans-serif; font-weight: 500;"> $ {{amount |
                number:'1.2-2'}} {{currency}}
            </div>


        </div>
    </div>
    <br>
    <div class="text-right btns_box">
        <button type="button" class="btn faint-grey col-3"
            style="box-shadow: none;text-transform: none; border: none; font-size: 14px !important;"
            (click)="closeModal()">No Thanks</button>
        &nbsp;&nbsp; <button type="button" class="btn orange text-white"
            style="box-shadow: none;text-transform: capitalize; font-size: 14px !important;"
            (click)="optInsaurance()">Add Insurance</button>
    </div>
</div>