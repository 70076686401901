<div id="DownloadPdfData1" class="col-12" style="border: none !important; padding: 10px 20px 0 20px !important;">
<app-spinner></app-spinner>
<!-- <iframe [src]="documentURL" width="100%" height="100%" style="width:100% !important; height: 800px !important; background-color: #FFF;"   embedded="true" scrolling="No"  frameborder="0"></iframe> -->
<!-- <div style="display: flex; justify-content: end;"><img style="margin-right: 10px; margin-bottom: 10px; height: 15px;" (click)="closeModal()" src="assets/img/Currier-Selection/close-x.svg" alt=""></div>
<iframe [src]="documentURL" width="100%" style="height: 80vh !important;" embedded="true" scrolling="No"  frameborder="0"></iframe> -->
  <div id="parentContainer">
        <div style="background: #fff;margin: auto;width: 100%;
        overflow: hidden;">
            <div id="contentInvoice" #contentInvoice class="bol-page_section"
                style="background: #fff;padding: 3px 5px;height: auto;border: 1px solid #D4D4D4; border-radius: 6px;float: left;">

                <div>
                    <div>
                        <div class="blo_connaissement_left">
                            <img style="width: 200px;padding: 0px 0 0 0;" src="assets/img/shipcarte-logo-350x130.png"
                                alt="" />
                        </div>
                        
                    </div>
                    <div >
                        <div class="float-right text-right" >
                            <h3 class="mb-0" style="font-size: 15px !important;font-weight: 600 !important;">INVOICE No. {{invoicePdfData?invoicePdfData.invoiceNo?invoicePdfData.invoiceNo:invoicePdfData.invoiceNo:'--'}}
                            </h3>
                            <!-- <h5 class="mb-0" style="font-size: 14px !important;font-weight: 500 !important;">{{data &&
                                formattedDate ? formattedDate : ''}} </h5> -->
                            <!-- <small class="orange-text">Set Reminder</small> -->

                        </div>
                        <div class="blo_connaissement_section">

                            <div class="row blo_connaissement_right p-2" >
                                <div class=" col-6 blo_left text-start" style="margin-left: -24px;">
                                    <div class="mt-3"
                                        style="font-size: 12px;font-family: poppins,sans-serif; font-weight: 600; ">BILL TO:</div>
                                    <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">
                                        <!-- {{data?.billTo.firstName}} {{data?.billTo.lastName}} -->
                                </div>
                                <div *ngIf="invoicePdfData.billingAddressInfo"
                                style="
                                  font-size: 12px;
                                  font-weight: 400;
                                  font-family: poppins, sans-serif;
                                "
                              >

                              {{
                                invoicePdfData.billingAddressInfo["name"]
                                  ? invoicePdfData.billingAddressInfo["name"]
                                  : ""
                              }}<br />

                                {{
                                  invoicePdfData.billingAddressInfo["streetAddress"]
                                    ? invoicePdfData.billingAddressInfo["streetAddress"]
                                    : ""
                                }}<br />
                
                                {{
                                  invoicePdfData.billingAddressInfo["city"]
                                    ? invoicePdfData.billingAddressInfo["city"]
                                    : ""
                                }}
                                {{
                                  invoicePdfData.billingAddressInfo["state"]
                                    ? invoicePdfData.billingAddressInfo["state"]
                                    : ""
                                }}
                                {{
                                  invoicePdfData.billingAddressInfo["country"]
                                    ? invoicePdfData.billingAddressInfo["country"]
                                    : ""
                                }}
                                {{
                                  invoicePdfData.billingAddressInfo["zipcode"]
                                    ? invoicePdfData.billingAddressInfo["zipcode"]
                                    : ""
                                }}
                                <br />
                
                                {{
                                  invoicePdfData.billingAddressInfo["telNo"]
                                    ? invoicePdfData.billingAddressInfo["telNo"]
                                    : ""
                                }}
                              </div>
                                </div>
                                <div class="col-6 blo_right text-end">
                                    <h5 class="mt-2 mb-0"
                                        style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        Total : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{subTotal? subTotal.toFixed(2) : '0.00'}}</span> </h5>
                                    <!-- <h3 class="mb-0 orange-text d-inline"
                                        style="font-size: 20px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        ${{data && data?.amountDue ? data && data?.amountDue : 0}} </h3> -->
                                        <h5 class="mt-2 mb-0"
                                        style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        Paid : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{amountPaid? amountPaid.toFixed(2) : '0.00'}}</span> </h5>
                                        <h5 class="mt-2 mb-0"
                                        style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        Balance Due : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{remainingAmount? remainingAmount.toFixed(2) : '0.00'}}</span> </h5>
                                    <!-- <h3 class="mb-0 orange-text d-inline"
                                        style="font-size: 20px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        ${{data && data?.amountDue ? data && data?.amountDue : 0}} </h3> -->
                                </div>
                            </div>
                            <div   *ngFor="
                            let data of invoicePdfData.invoiceOrderInfoList;
                            let i = index
                          ">

                            <div class="order-details_section p-2">
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                    <div><b>ORDER NO.</b></div>
                              
                                    <div style="color:#FF8D00;">{{data && data?.orderNo ? data && data?.orderNo :
                                        ''}}
                                    </div>
                                </div>
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                    <div><b>CUSTOMER REF NO.</b></div>
                             
                                    <div>{{data?.customerRefNo!='' ? data?.customerRefNo : '--'}}</div>
                                </div>
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                    <div><b>ACCOUNT NO.</b></div>
                                
                                    <div>{{data && data?.accountNo ? data && data?.accountNo : ''}} </div>
                                </div>
    
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                    <div><b>TERMS</b></div>
                              
                                    <div>{{data && data?.terms ? data && data?.terms : ''}}</div>
                                </div>
                            </div>
                            <div class="table_start p-2" *ngFor="
                            let orderData of data.invoiceOrderInfoDetailsList;
                            let j = index
                          ">
                                <div class="table-data">
                                  <div
                                    class="shipper_box p-2"
                                    style="border-right: 1px solid #c4cdd5; width: 50%"
                                  >
                                    <div><b>SHIPPED:</b></div>
                                    <div>
                                      {{
                                        orderData && orderData?.shippedDate
                                          | date : "MM-dd-yyyy"
                                      }}
                                    </div>
            
                                    <div><b>Probill No. :</b></div>
                                    <div>{{ orderData && orderData?.probillNo }}</div>
                                  </div>
                                  <div class="consignee_box p-2">
                                    <div><b>RECEIVED:</b></div>
                                    <div *ngIf="data?.deliveryDate != '--'">
                                      {{ orderData?.deliveryDate | date : "MM-dd-yyyy" }}
                                    </div>
                                    <div *ngIf="data?.deliveryDate == '--'">
                                      {{ orderData?.deliveryDate }}
                                    </div>
            
                                    <div><b>Customer Ref No. :</b></div>
                                    <div>
                                      {{
                                        orderData?.customerRef
                                          ? orderData.customerRef
                                          : "--"
                                      }}
                                    </div>
                                  </div>
                                </div>
            
                                <div class="table_start">
                                  <div class="table-data">
                                    <div
                                      class="shipper_box"
                                      style="border-right: 1px solid #c4cdd5; width: 50%"
                                    >
                                      <div
                                        class="p-2"
                                        style="
                                          border-bottom: 1px solid #c4cdd5;
                                          padding: 2px 4xp 2px 4px;
                                          background-color: #f5f6fa;
                                        "
                                      >
                                        <b>SHIPPER:</b>
                                      </div>
                                      <div class="p-2">
                                        <div>{{ orderData["shipperAddress"]["name"] }}</div>
                                        <div>
                                          {{
                                            orderData["shipperAddress"]["streetAddress"]
                                              ? orderData["shipperAddress"]["streetAddress"]
                                              : ""
                                          }}
                                        </div>
              
                                        <div>
                                          {{
                                            orderData["shipperAddress"]["city"]
                                              ? orderData["shipperAddress"]["city"]
                                              : ""
                                          }}
                                          {{
                                            orderData["shipperAddress"]["state"]
                                              ? orderData["shipperAddress"]["state"]
                                              : ""
                                          }}
                                          {{
                                            orderData["shipperAddress"]["country"]
                                              ? orderData["shipperAddress"]["country"]
                                              : ""
                                          }}
                                          {{
                                            orderData["shipperAddress"]["zipcode"]
                                              ? orderData["shipperAddress"]["zipcode"]
                                              : ""
                                          }}
                                        </div>
                                      </div>
                                     
                                    </div>
                                    <div class="consignee_box">
                                      <div
                                        class="p-2"
                                        style="
                                          border-bottom: 1px solid #c4cdd5;
                                          padding: 2px 4xp 2px 4px;
                                          background-color: #f5f6fa;
                                        "
                                      >
                                        <b>CONSIGNEE:</b>
                                      </div>
                                      <div class="p-2">
                                        <div>{{ orderData["consigneeAddress"]["name"] }}</div>
                                      <div>
                                        {{
                                          orderData["consigneeAddress"]["streetAddress"]
                                            ? orderData["consigneeAddress"]["streetAddress"]
                                            : ""
                                        }}
                                      </div>
            
                                      <div>
                                        {{
                                          orderData["consigneeAddress"]["city"]
                                            ? orderData["consigneeAddress"]["city"]
                                            : ""
                                        }}
                                        {{
                                          orderData["consigneeAddress"]["state"]
                                            ? orderData["consigneeAddress"]["state"]
                                            : ""
                                        }}
                                        {{
                                          orderData["consigneeAddress"]["country"]
                                            ? orderData["consigneeAddress"]["country"]
                                            : ""
                                        }}
                                        {{
                                          orderData["consigneeAddress"]["zipcode"]
                                            ? orderData["consigneeAddress"]["zipcode"]
                                            : ""
                                        }}
                                      </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>

    
                            <div class="pro_details_table">
                                <table style="background-color: #F5F6FA !important;">
                                    <tr class="yellow-header" style="width: 100%;float: left;">
                                        <th style="width: 14%;padding-left:10px">QTY</th>
                                        <th style="width: 35%;">DESCRIPTION</th>
                                        <th style="width: 12%;">WEIGHT</th>
                                        <th style="width: 12%;">CLASS</th>
                                        <th style="width: 12%;">CHARGES</th>
                                        <th style="width: 12%;text-align: end;">ADJUSTMENTS</th>
                                    </tr>
                                    <tr
                          *ngFor="let key of orderData.items"
                          style="
                            padding: 0px 0px;
                            border-bottom: 1px solid #c4cdd5;
                            width: 100%;
                            float: left;
                          "
                        >
                          <td style="width: 14%; padding-left: 10px">
                            {{ key["quantity"] ? key["quantity"] : "" }}
                          </td>
                          <td style="width: 35%">
                            {{ key["itemDesc"] ? key["itemDesc"] : "" }}
                          </td>
                          <td style="width: 12%">
                            {{
                              key["weight"]
                                ? key["weight"] * key["quantity"]
                                : ""
                            }}
                            {{ key["weightUnit"] ? key["weightUnit"] : "" }}
                          </td>
                          <td style="width: 12%">
                            {{ key["freightClass"] ? key["freightClass"] : "" }}
                          </td>
                          <td style="width: 11%">
                            {{
                              
                                "$" + orderData.chargeInfo.carrierCharge
                                
                            }}
                          </td>
                          <td style="width: 15%; text-align: right; color: red;padding-right: 26px;">
                            {{
                                orderData.chargeInfo?.adjustmentCharge
                                 > 0
                                ? "$" + orderData.chargeInfo?.adjustmentCharge
                                : "--"
                            }}
                          </td>
                        </tr>
                        <tr
                          *ngFor="
                            let chargeItem of orderData.chargeInfo.charges;
                            let i = index
                          "
                          style="
                            padding: 0px 0px;
                            width: 100%;
                            float: left;
                            height: 25px;
                          "
                        >
                          <td style="width: 14%; padding-left: 3px"></td>
                          <td style="width: 36%; text-align: left">
                            {{ chargeItem.chargeName }}
                          </td>
                          <td
                            style="
                              width: 50%;
                              text-align: right;
                              padding: 0px 26px 0px 0px;
                            "
                          >
                            $ {{ chargeItem.chargeAmount }}
                          </td>
                        </tr>

                        <tr
                          *ngFor="let key of orderData.items"
                          style="padding: 0px 0px; width: 100%; float: left"
                        >
                          <td style="width: 100%; padding-left: 10px">
                            <b>Dimensions :</b
                            >{{ key["length"] ? key["length"] : "" }} L x
                            {{ key["width"] ? key["width"] : "" }} W x
                            {{ key["height"] ? key["height"] : "" }} H<br />
                            <b>SERVICE :</b
                            >{{
                              orderData["shipmentMode"]
                                ? orderData["shipmentMode"]
                                : ""
                            }}<br />
                            <b>SHIP VIA :</b
                            >{{
                              orderData["carrierName"]
                                ? orderData["carrierName"]
                                : ""
                            }}<br />
                            <b>PARTNER TRACKING NO. / SIUVIE NO. :</b
                            >{{ orderData?.partnerRefNo }}<br />
                          </td>
                        </tr>
    
                                </table>
                            </div>



                            <div class="pro_details_table">

                <div
                class="row mt-2 mr-0"
                style="font-size: 8px; width: 100% !important"
              >
                <div class="col-7">&nbsp;</div>
                <div class="col-5">
                  <div class="row">
                    <div class="col-6">
                      <div style="float: left"><b style="font-size: 10px;">SUB-TOTAL </b></div>
                    </div>
                    <div class="col-6">
                      <div style="float: right;font-size: 10px;">
                        ${{
                          orderData.chargeInfo.carrierCharge | number : "1.2-2"
                        }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="row" style="margin-top: -25px !important;"
                    *ngFor="let taxData of orderData.chargeInfo?.taxInfo"
                  >
                    <div class="col-6">
                      <div style="float: left">
                        <b style="font-size: 10px;"
                          >TAX -{{
                            taxData?.taxPercent != null
                              ? taxData?.taxPercent
                              : 0
                          }}% {{ taxData?.taxName }}</b
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div style="float: right;font-size: 10px;">
                        ${{ taxData?.taxAmount | number : "1.2-2" }}
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-6">
                      <div style="float: left">
                        <b style="font-size: 10px;">TOTAL</b>
                      </div>
                    </div>
                    <div class="col-6">
                      <div style="float: right">
                        <b>
                          <span style="font-size: 15px; color: #ff8d00"
                            >${{
                              orderData.chargeInfo?.dueAmount
                                | number : "1.2-2"
                            }}
                          </span>
                          {{ orderData.chargeInfo?.currency }}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                                <!-- <tr style="padding: 0px 0px;width: 100%;float: left;font-size: 9px;">
                                    <td style="width:29%;
                                                 float:right ;">
                                        <div style="float: left;"><b>SUB-TOTAL</b></div>
                                        <div style="float: right;">${{data?.subTotal | number : '1.2-2'}}</div>
                                        <br>
                                        <div style="float: left;"><b>TAX -{{data?.taxRate!=null?data?.taxRate:0}}% {{data?.taxName}}</b></div>
                                                            <div style="float: right;">${{data?.totalTaxes | number : '1.2-2'}}</div>
                                        <br>
                                        <hr>
                                        <div style="float: left;">
                                            <b>TOTAL </b>
                                        </div>
                                        <div style="float: right;">
                                            <b>
                                                <span style="font-size: 15px;color: #FF8D00;">${{data?.amountDue}} </span>
                                                {{data?data?.currency?data?.currency:'':''}}
                                            </b>
                                        </div>
                                    </td>
                                </tr> -->
        
                            </div>                     
                                 </div>


                        </div>

                        
                            </div>
                        <table>
                            <span class="break-page"></span>
        
                            <div class="html2pdf__page-break" ></div>
                            <tr  style="padding: 4px 0px;width: 100%;float: left;font-size: 8px;">
                                <td style="width:45%;padding: 0px 0px;">
        
                                    <div class="mt-1 mb-5 ">
                                        <div>
                                            <b>1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA</b><br>
                                            <b>888.424.8878 EXT. 5<span style="font-size: 8px;color: #FF8D00;">
                                                    accounting@shipcarte.com</span></b><br>
                                        </div>
                                        <a style="margin-right: 8px;" href="https://www.linkedin.com/company/shipcarte-inc/"
                                            target="_blank">
                                            <img src="assets/img/shipment/Black-Linkedin.svg" width="17px"
                                                class="m-1 text-center">
                                        </a>
                                        <a style="margin-right: 8px;" href="https://www.instagram.com/shipcarte/?hl=en%C2%A0"
                                            target="_blank">
                                            <img src="assets/img/shipment/Black-Insta.svg" width="17px" class="m-1 text-center">
                                        </a>
                                        <!-- <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                            <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                                        </a> -->
                                        <a style="margin-right: 8px;" href="https://twitter.com/shipcarte?lang=en%C2%A0"
                                            target="_blank">
                                            <img src="assets/img/shipment/Black-Twitter.svg" width="17px"
                                                class="m-1 text-center">
                                        </a>
                                        <a style="margin-right: 8px;" href="https://www.facebook.com/ShipCarte/"
                                            target="_blank">
                                            <img src="assets/img/shipment/Black-Facebook.svg" width="17px"
                                                class="m-1 text-center">
                                        </a>
                                    </div>
                                </td>
                                <td style="width:9%;"></td>
                                <td style="width:28%;">
                                    <div>
                                        <b>
                                            <span style="font-size: 20px;color: #FF8D00;">THANK YOU / MERCI</span>
                                        </b>
                                        <!-- <img width="50px" src="assets/img/invoice/scikkids.png" > -->
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                
            </div>
           

            <!-- <br> -->
            <div style="margin-left: 43%;">
                <button type="button" class="btn faint-grey orangeBtn col-3 mt-1" style="box-shadow: none;text-transform: capitalize;"
                    (click)="closeModal()">Close</button>
            </div>

        </div>
    </div>
    <!-- <div style="margin-left: 43%;">
        <button type="button" id="printBtn" class="btn faint-grey orangeBtn col-3 mt-1" style="box-shadow: none;text-transform: capitalize;"
            (click)="printFile()">Print</button>
    </div> -->
</div>
