import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbPaginationNext } from '@ng-bootstrap/ng-bootstrap';
import { NgSelect2Module } from 'ng-select2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { SharedModule } from './shared/shared/shared.module';

// import { SidebarComponent } from './core/sidebar/sidebar.component';
import { PrivacyComponent } from './_helpers/popup-dialog/privacy/privacy.component';
import { TermsComponent } from './_helpers/popup-dialog/terms/terms.component';
import { VerifyEmailComponent } from './_helpers/popup-dialog/verify-email/verify-email.component';
import { VerifyMobileComponent } from './_helpers/popup-dialog/verify-mobile/verify-mobile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { getQuoteService } from './services/get-quote/get-quote.service';
import {MatChipsModule} from '@angular/material/chips';
import {MatSelectModule} from '@angular/material/select';
import { SidebarComponent } from './../app/_helpers/sidebar/sidebar.component';
import { QuoteHistoryService } from './services/quote-history/quote-history.service';
import { ShipperAddressEditComponent } from './_helpers/popup-dialog/ConfirOrder/Shipper/shipper-address-edit.component';
import { ConfirmOrderService } from './services/ConfirmOrder/confirm-order.service';
import { PriceBrackupService } from './services/PRiceBrackup/price-brackeup.service';
import { ShimentSummryService } from './services/ShipmentSummurry/shipment-summary.service';
import { SelectCarrierService } from './services/Carrier-Selection/select-carrier.service';

import { ManageCarrierComponent } from './Admin/manage-carrier/manage-carrier.component';
import { AdminService } from './services/AdminService/admin.service';
import { ContactShipperComponent } from './_helpers/popup-dialog/contact-shipper/contact-shipper.component';
import { ContactConsigneeComponent } from './_helpers/popup-dialog/contact-consignee/contact-consignee.component';
import { InsuranceComponent } from './_helpers/popup-dialog/insurance/insurance.component';

// import { DocumentComponent } from './shipment/document/document.component';
import { AddFavoriteComponent } from './_helpers/popup-dialog/add-favorite/add-favorite.component';
import { EmailDocumentComponent } from './_helpers/popup-dialog/email-document/email-document.component';
import { AddressBook } from './services/AddressBook/addressBook.service';
import { NgxMaskModule } from 'ngx-mask';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// import { CommercialInvoiceComponent } from './commercial-invoice/commercial-invoice.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { CommercialInvoiceSignatureComponent } from './_helpers/popup-dialog/commercial-invoice-signature/commercial-invoice-signature.component';
import { DashboardService } from './services/Dashboard/dashboard.service';
import { EditDashboardComponent } from './_helpers/popup-dialog/edit-dashboard/edit-dashboard.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { CreditApplicationComponent } from './user-preferences/credit-application/credit-application.component';
import { GridsterModule } from 'angular-gridster2';
import { CommercialInvoiceConfirmationComponent } from './_helpers/popup-dialog/commercial-invoice-confirmation/commercial-invoice-confirmation.component';
import { CreditApplicationSignatureComponent } from './_helpers/popup-dialog/credit-application-signature/credit-application-signature.component';
import { UserPreferencesTermsComponent } from './_helpers/popup-dialog/user-preferences-terms/user-preferences-terms.component';
import {MatTabsModule} from '@angular/material/tabs';
import { UserPreferencesEmailComponent } from './_helpers/popup-dialog/user-preferences-email/user-preferences-email.component';
import { HelpComponent } from './help/help.component';
import { ZipPostalCodesComponent } from './_helpers/popup-dialog/quick-actions/zip-postal-codes/zip-postal-codes.component';
import { CalculateDistanceComponent } from './_helpers/popup-dialog/quick-actions/calculate-distance/calculate-distance.component';
import { FreightClassCalculatorComponent } from './_helpers/popup-dialog/quick-actions/freight-class-calculator/freight-class-calculator.component';
import { DimensionalWeightCalculatorComponent } from './_helpers/popup-dialog/quick-actions/dimensional-weight-calculator/dimensional-weight-calculator.component';
import { AddressDeleteConfirmComponent } from './_helpers/popup-dialog/AddressBook/address-delete-confirm/address-delete-confirm.component';
import { LoaderComponent } from './_helpers/loader/loader.component';
import { InfoComponent } from './_helpers/popup-dialog/common/info/info.component';
// import { CustomPackageComponent } from './_helpers/popup-dialog/AddressBook/custom-package/custom-package.component';
import { AddContactDetailsComponent } from './_helpers/popup-dialog/AddressBook/add-contact-details/add-contact-details.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { RemoveFavoriteComponent } from './_helpers/popup-dialog/remove-favorite/remove-favorite.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ViewReceiptComponent } from './_helpers/popup-dialog/invoices/view-receipt/view-receipt.component';
import { ViewInvoiceComponent } from './_helpers/popup-dialog/invoices/view-invoice/view-invoice.component';
import {PdfPrintInvoiceComponent} from './_helpers/popup-dialog/invoices/pdf_print/pdf-print.component';
import { ReportsComponent } from './reports/reports.component';
import { CreditApplicationBackButtonComponent } from './_helpers/popup-dialog/credit-application-back-button/credit-application-back-button.component';
import { DownloadLabelComponent } from './_helpers/popup-dialog/download-label/download-label.component';
import { CustomInfoSignatureComponent } from './_helpers/popup-dialog/custom-info-signature/custom-info-signature.component';
import { CustomInfoNormalformComponent } from './_helpers/popup-dialog/custom-info-normalform/custom-info-normalform.component';
import { PriceSummaryComponent } from './_helpers/popup-dialog/price-summary/price-summary.component';
import { ViewBolComponent } from './_helpers/popup-dialog/invoices/view-bol/view-bol.component';
import { ViewComInvComponent } from './_helpers/popup-dialog/invoices/view-com-inv/view-com-inv.component';
import { AddFavoriteConfirmComponent } from './_helpers/popup-dialog/add-favorite-confirm/add-favorite-confirm.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatTooltipModule} from '@angular/material/tooltip';
import 'hammerjs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonInterceptor } from './common.interceptor';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AgmDirectionModule } from 'agm-direction';
 
import { SafeUrlPipePipe } from './pipes/safe-url-pipe.pipe';
import { FavoriteNewComponent } from './favorite-new/favorite-new.component';
import { ViewBolDocComponent } from './_helpers/popup-dialog/invoices/view-bol-doc/view-bol-doc.component';
import { ViewCommInvQuickActionComponent } from './_helpers/popup-dialog/invoices/view-comm-inv-quick-action/view-comm-inv-quick-action.component';
import { ViewConslidatedInvoiceComponent } from './_helpers/popup-dialog/invoices/view-conslidated-invoice/view-conslidated-invoice.component';
import { ViewCommercialInvoiceComponent } from './_helpers/popup-dialog/invoices/view-commercial-invoice/view-commercial-invoice.component';
import { CommonModule } from '@angular/common';

import { AddPackagesComponent } from './new-shipcarte-pro/add-packages/add-packages.component';
import { AddProductComponent } from './new-shipcarte-pro/add-product/add-product.component';
import { ViewPodComponent } from './_helpers/popup-dialog/view-pod/view-pod.component';
import { SpinnerModule } from './spinner/spinner.module';
import { NewViewReceiptComponent } from './_helpers/popup-dialog/invoices/new-view-receipt/new-view-receipt.component';
// import { EditCustomPackageComponent } from './_helpers/popup-dialog/custom-package/edit-custom-package/edit-custom-package.component';
// import { NewCustomPackageComponent } from './_helpers/popup-dialog/custom-package/new-custom-package/new-custom-package.component';

//import { AddressBookComponent } from './address-book/address-book.component';
// import { showErrors } from '../app/_helpers/error-show-helper';
// import { HeaderComponent } from './_helpers/header/header.component';

// import { LeftsidebarComponent } from './leftsidebar/leftsidebar.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    PrivacyComponent,
    TermsComponent,
     
    VerifyEmailComponent,
    VerifyMobileComponent,
    ShipperAddressEditComponent,
    ManageCarrierComponent,
    ManageCarrierComponent,
    ContactShipperComponent,
    ContactConsigneeComponent,
    InsuranceComponent,
    // DocumentComponent,
    AddFavoriteComponent,
    EmailDocumentComponent,
    // CommercialInvoiceComponent,
    ShipmentComponent,
    CommercialInvoiceSignatureComponent,
    EditDashboardComponent,
    UserPreferencesComponent,
    CreditApplicationComponent,
    CommercialInvoiceConfirmationComponent,
    CreditApplicationSignatureComponent,
    UserPreferencesTermsComponent,
    UserPreferencesEmailComponent,
    HelpComponent,
    ZipPostalCodesComponent,
    CalculateDistanceComponent,
    FreightClassCalculatorComponent,
    DimensionalWeightCalculatorComponent,
    AddressDeleteConfirmComponent,
    LoaderComponent,
    InfoComponent,
    // CustomPackageComponent,
    AddContactDetailsComponent,
    FavoriteComponent,
     FavoriteNewComponent,
    RemoveFavoriteComponent,
    InvoicesComponent,
    ViewReceiptComponent,
    ViewInvoiceComponent,
    PdfPrintInvoiceComponent,
    ReportsComponent,
    CreditApplicationBackButtonComponent,
    DownloadLabelComponent,
    CustomInfoSignatureComponent,
    CustomInfoNormalformComponent,
    PriceSummaryComponent,
    ViewBolComponent,
    ViewComInvComponent,
    AddFavoriteConfirmComponent,
    ViewBolDocComponent,
    ViewCommInvQuickActionComponent,
  
   
    ViewConslidatedInvoiceComponent,
    ViewCommercialInvoiceComponent,
 
    AddPackagesComponent,
    AddProductComponent,
    ViewPodComponent,
    NewViewReceiptComponent
    // EditCustomPackageComponent,
    // NewCustomPackageComponent

    //AddressBookComponent,

    // HeaderComponent,
    // LeftsidebarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // NgbModule,
    NgSelect2Module,
    MatChipsModule,
    MatSelectModule,
    GridsterModule,
    MatTabsModule,
    Ng2FlatpickrModule,
    NgxMatSelectSearchModule,
    NgxPayPalModule,
    DragDropModule,
    FormsModule,

    MatTooltipModule,
    // showErrors,
    NgbModule,GooglePlaceModule,
    NgxMaskModule.forRoot(),
    HttpClientModule, AngularSvgIconModule.forRoot(),SocialLoginModule,FormsModule, ReactiveFormsModule,SharedModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GooglePlacesApiKey
    }),
    AgmDirectionModule,
    CommonModule,
    SpinnerModule
    // AgmCoreModule.forRoot({
    //   apiKey: environment.GooglePlacesApiKey
    // }),

    
  ],
  providers: [
    QuoteHistoryService,
    ConfirmOrderService,
    PriceBrackupService,
    ShimentSummryService,
    SelectCarrierService,
    AdminService,AddressBook,DashboardService,
    {
      // getQuoteService,
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              /** pragra api key */
              //'934133317327-j36bhnqjvmul10usj4jnkmi7u9d3uc2f.apps.googleusercontent.com'
              /**client api key */
              '538975075279-3enankt7gcvrp1sbaiuhhq571qh8vt5b.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
