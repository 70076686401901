import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-new-view-receipt',
  templateUrl: './new-view-receipt.component.html',
  styleUrls: ['./new-view-receipt.component.css'],
})
export class NewViewReceiptComponent implements OnInit {
  @Input() data;
  invoiceTotal: any = '';
  iscreditLimitApplied: boolean = false;
  constructor(public activeModal: NgbActiveModal) {}

  formattedDate: any;
  printInvoice: boolean;
  amountPaid: any = 0.0;
  paymentMethod: any = [];
  currentOrderNoIndex = -1;
  invoiceNumber: any;
  totalAmount: any;
  cardType: any;

  orderChargeArray() {
    this.data.orderChargeInfo = Object.entries(this.data.orderChargeInfo); // Converts to an array of [key, value] pairs
  }

  ngOnInit(): void {
    this.orderChargeArray();
    this.invoiceNumber = this.data.invoiceNumber;
    this.amountPaid = this.data.paidAmt;
    this.formattedDate = formatDate(
      this.data.invoiceDate,
      'MM/dd/yyyy',
      'en-US'
    );
    this.paymentMethod = this.data.paymentMethodList;
    this.totalAmount = this.data.totalAmount;
    this.cardType = this.data.cardTypeList ? this.data.cardTypeList[0] : '';
    if (this.data.paymentMethodList.includes('Credit Limit')) {
      this.iscreditLimitApplied = true;
    } else {
      this.iscreditLimitApplied = false;
    }
  }

  printComponent() {
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  closeModal() {
    this.activeModal.close();
  }
}
