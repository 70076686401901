import { Component, Input, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { jsPDF } from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import { formatDate } from '@angular/common';
import { CommonService } from '../../../../services/common.service'
import { LoadingService } from 'src/app/services/loader.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/services/document.service';
//import { PDFDocumentProxy } from 'ng2-pdf-viewer';
@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {
  documentURL:any;
  orderData: any;
  totalCharges: any = 0.0;
  totalTaxes: any = 0.0;
  amountDue: any = 0.0;
  subTotal:any=0.0;
  amountPaid:any=0.0;
  remainingAmount:any=0.0;
  parentInvoiceObject:any;
   @Input() data;
  //data:any
  printBOL: boolean;
  formattedDate: any;
  charges: any;
  //@Input() orderList;
  orderList:any = [];
  safeHtml: SafeHtml;
  url: any;
  loading: boolean;
  invoicePdfData: any;
  listOrderId: string[];
  constructor(public activeModal: NgbActiveModal, 
    public docService:DocumentService, public common: CommonService, 
     private  _loaderService: LoadingService,
      private sanitizer: DomSanitizer, private toast: ToastrService) {
        this.getInvoicePdfData();

   }

  ngOnInit(): void {

    this._loaderService.stringSubject.next(true);
    this.printBOL = true;
    console.log(this.data, this.orderList);
    this.parentInvoiceObject = this.orderList.filter((ele)=>ele.parentInvoiceNumber==ele.invoiceNumber);
    if(this.orderList.length<1){
      this.orderList.push(this.data); 
    }
    let amountDueforsession=0.0;
        let amountpaidforsession=0.0;
        let totalforSession=0.0;
        this.orderList?.forEach(el=>{
          amountDueforsession = amountDueforsession + el?.amountDue;
          amountpaidforsession = amountpaidforsession + el?.amountPaid;
          totalforSession = totalforSession + el?.charged;
        })
        let dataObj={};
    dataObj={"invoiceTotal":totalforSession, "amountPaid":amountpaidforsession, "amountDue":amountDueforsession}
    sessionStorage.setItem('ordersIdsInInvoiceOrderDetail', JSON.stringify(dataObj))
    this.orderData = JSON.parse(sessionStorage.getItem("ordersIdsInInvoiceOrderDetail"));
    // this.remainingAmount=JSON.parse(sessionStorage.getItem('ordersIdsInInvoiceOrderDetail')).amountDue;
    // this.subTotal=JSON.parse(sessionStorage.getItem('ordersIdsInInvoiceOrderDetail')).invoiceTotal;
    // this.amountPaid = JSON.parse(sessionStorage.getItem('ordersIdsInInvoiceOrderDetail')).amountPaid;
    this.totalCharges=this.data?.charged;
    this.totalTaxes=this.data?.totalTaxes
    this.amountDue=this.data?.amountDue
    // this.data?.items.map((element, index) => {
    //   console.log(element)
    //     this.totalCharges = this.totalCharges + parseFloat(element.charged);
    //     this.totalTaxes = this.totalTaxes + parseFloat(element.totalTaxes)
    //     this.amountDue = this.amountDue + parseFloat(element.amountDue);
      
    // })
    // console.log('data here in view invoice', this.orderList)
    if (this.common.printInvoiceFromInvoices === true) {
      this.sss();
      this.common.printInvoiceFromInvoices = false;
    }

    setTimeout(() => {
      this._loaderService.stringSubject.next(false);
    }, 2000);
    setTimeout(() => {
      let element;
      element = document.getElementById('DownloadPdfData1');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth'})
      }
    }, 0)


  }



  getInvoicePdfData(){
    this._loaderService.stringSubject.next(true);
    this.docService.getInvoicePdfData("",this.listOrderId).subscribe((result : any)=>{      
      if (result) {
      this._loaderService.stringSubject.next(false);

      this.invoicePdfData = result;

      if(result && result.invoiceOrderInfoList){
        result.invoiceOrderInfoList.forEach((invoice)=> {
          if(invoice && invoice.invoiceOrderInfoDetailsList){
            invoice.invoiceOrderInfoDetailsList.forEach((record)=>{
              this.remainingAmount+= parseFloat(record.chargeInfo.dueAmount);
              this.subTotal+= parseFloat(record.chargeInfo.netCharge);
              this.amountPaid+= parseFloat(record.chargeInfo.paidAmount);
            })
          }
        })
      }
      }
    }, error => {
      this._loaderService.stringSubject.next(false);
    });

  }

  closeModal() { this.activeModal.close(); }

  print(doc) {
    if (doc == 'bol') {
      this.printBOL = true;
    }
  }
  callBackFn(pdf: any) {
    // do anything with "pdf"
    console.log("pdf",pdf);
    document.querySelector("#loadingIcon").remove();
 }
 pageRendered(e: CustomEvent) {
  this.loading = false;
  //console.log('(page-rendered)', e);
}
  sss() {
    this.savePdf('','','');
  }
  async savePdf(modelToOpn, action, defaultEmail?) {
    this._loaderService.stringSubject.next(true);
    let currentcomp = this;
    var element = document.querySelector('#contentInvoice');
    var opt = {
      margin: 0.1,
      filename: "INV_" + sessionStorage.getItem('invoiceId') + ".pdf",
      image: { type: 'jpeg', quality: 1.5 },
      html2canvas: { dpi: 192, scale: 2, letterRendering: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true },
      pageBreak: { mode: 'css', after: '.break-page' }
    };
    // New Promise-based usage:
    let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then((data: Blob) => {
      return data
    })

    currentcomp.sendPDFToServer(worker, modelToOpn, action, defaultEmail);
  }
  sendPDFToServer(pdfContent, modelToOpn, action, defaultEmail?) {
   
      this._loaderService.stringSubject.next(true);
    const formdata: FormData = new FormData();
    formdata.append("isCustomerEmailReq", 'false');
    formdata.append("customerId", JSON.parse(localStorage.getItem('loggedInUserDetails')).uuid);
    var date = new Date().valueOf();
    const blobFile = new Blob([pdfContent], {
      type: 'application/pdf',
    });
    let orderId_selected = this.parentInvoiceObject.length>0?this.parentInvoiceObject[0].orderId:'document';
    var dummyFile = new File([pdfContent], "INV_" + orderId_selected + ".pdf");
    formdata.append("file", dummyFile);
    
     let url1 = environment.apiPath + "/api/sc/admin/invoice/upload";
    let promise1 = this.common.postFileHttpService(url1, formdata);

    promise1.then((res: any) => {
     console.log("res******", res);
        this.documentURL = this.sanitizer.bypassSecurityTrustResourceUrl(res?.documentUrl);
        //document.getElementById('parentContainer').style.display = 'none';
        setTimeout(() => {
          this._loaderService.stringSubject.next(false);
        }, 1000);
    }).catch((error) => {
      this.toast.error('Something Went wrong while saving PDF.', 'Please try again.', {
        timeOut: 10000,
      });
    });

  }
  printFile(){
    window.print(); 
  }
  download() {
    let compData = this;
    html2canvas(document.querySelector('#content'), { useCORS: true }).then(function (canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        }
        pdf.save("invoice_" + compData['data']['invoiceNumber'] + '.pdf');
      };
    });

  }
}

