<div class="container outerWrapper" >
    <div class="row" style="border-bottom: 1px solid #E3E7EE; padding: 15px;margin-bottom: 0px;">
        <div class="col-lg-8">
            <h3 class=" p-0 m-0 d-inline" style=" font-size: 18px !important; font-weight: 500 !important;
            font-family: ''Poppins'sans-serif' !important; color: #303447 !important;">View Receipt </h3>
            <span class="blue-text ml-3 cursor-p" (click)="printComponent()">Print</span>
        </div>
        <div class="col-lg-4 text-end">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true"><span class="material-icons">close </span></span>
            </button>
        </div>
    </div>
    <div class="row" id="component" style="padding: 15px;">
        <div class="col-lg-12 p-0">
            <div  class=" printable printableInvoice">
                <h3 class="mb-2 text-center" style="font-size: 19px !important; color: #151522 !important;">
                    INVOICE#{{invoiceNumber?invoiceNumber:'--'}}</h3>
                <h4 class="text-center"
                    style="font-size: 18px !important; font-weight: 400 !important; color: #151522 !important;">Receipt#
                </h4>
                <div class="row" style="margin-bottom: 18px !important; margin-top: 30px !important;">
                    <div class="col text-dark p-0">
                        Amount Paid
                    </div>
                    <div class="col text-reg-one p-0">
                       $ {{ amountPaid != null ? amountPaid : '0.00' }}

                    </div>
                </div>
                <div class="row" style="margin-bottom: 18px !important;">
                    <div class="col text-dark p-0">
                        Date Paid
                    </div>
                    <div class="col text-reg-one p-0">
                        {{data && formattedDate ? formattedDate : ''}}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 18px !important;">
                    <div class="col text-dark p-0">
                        Payment Method
                    </div>
                  
                    <div class="col text-reg-one p-0" >
                        <img *ngIf="iscreditLimitApplied" src="https://dev-shipcarte-docs.s3.amazonaws.com/credit-approved-25042023.svg" style="height: 25px; width: 25px;" > 
                         <img style="height: 12px;
                        object-fit: contain;" *ngIf=" cardType == 'VISA' || cardType=='MASTERCARD' || cardType=='AMERICAN_EXPRESS'" class=""
                            src="assets/img/payment/{{cardType=='VISA'?'visa_icon.svg':cardType=='MASTERCARD'?'master.svg':cardType=='AMERICAN_EXPRESS'?'amex_icon.svg':''}}"  >
                        {{paymentMethod}}
                    </div>
                  

                </div>
                <h3 class="text-dark" style="padding: 12px 0 10px 0; line-height: 1 !important;">Summary</h3>
                <div class="row" style="background-color: #F7F9FC; margin-bottom: 0;">
                    <div class="col-12">
                        <h4 class="text-dark" style="padding-top: 10px;">Order No.</h4>
                    </div>
                    <hr>
                    <div class="col-12">
                        <div class="row" style="margin: 0; padding: 10px 0px;" *ngFor="let value of data.orderChargeInfo; let in=index">
                            <div class="col text-reg-one text-start pl-0" [ngStyle]="{'font-weight':in===currentOrderNoIndex?'500':'400'}"> {{value[0] ? value[0] : ''}}
                            </div>
                            <div class="col  text-reg-one pr-0" [ngStyle]="{'font-weight':in===currentOrderNoIndex?'500':'400'}">$ {{value[1] ? (value[1]) :
                                ''}} </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-12">
                        <div class="row" style="margin: 0; padding: 10px 0px;">
                            <div class="col text-dark pl-0">Total Amount</div>
                            <div class="col text-reg-one text-dark pr-0"> 
                                $ {{totalAmount}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>